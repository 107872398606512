import { Injectable } from '@angular/core';
import { AlertController, IonicSafeString } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private alertController: AlertController) {}

  async presentAlert(text) {
    const alert = await this.alertController.create({
      message: new IonicSafeString(text),
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentQuestion(
    text: string,
    okFunc: () => void,
    header: string = null,
    subHeader: string = null,
    okText = 'Ok',
    cancelText = 'Abbrechen',
    cssClassOk = null,
    cssClassCancel = null,
    cancelFunc: () => void = null,
  ): Promise<void> {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message: new IonicSafeString(text),
      buttons: [
        { text: okText, handler: okFunc, cssClass: cssClassOk ?? 'secondary-btn' },
        { text: cancelText, handler: cancelFunc, role: 'cancel', cssClass: cssClassCancel ?? 'secondary-btn' }
      ]
    });

    await alert.present();
  }
}
