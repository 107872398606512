<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissPasswordModal()">
        <ion-icon slot="start" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Passwort ändern</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="submitChangePassword(form)" [strong]="true">Speichern</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <form #form="ngForm">
      <ion-card class="ion-card-tint" style="margin-bottom: 10px !important">
        <ion-list class="ion-card-tint less-margin">
          <!-- Old Password -->
          <ion-item class="ion-item-tint">
            <ion-label position="floating">Altes Passwort</ion-label>
            <ion-input
              type="password"
              placeholder="Altes Passwort"
              name="oldPassword"
              #oldPassword="ngModel"
              [(ngModel)]="userData.oldPassword"
              required
              [class.is-invalid]="oldPassword.invalid && oldPassword.touched"
            ></ion-input>
            <div *ngIf="oldPassword.invalid && oldPassword.touched" class="invalid-feedback">
              <div *ngIf="oldPassword.errors?.required">Das alte Passwort wird benötigt</div>
            </div>
          </ion-item>

          <!-- New Password -->
          <ion-item class="ion-item-tint">
            <ion-label position="floating">Neues Passwort</ion-label>
            <ion-input
              type="password"
              placeholder="Neues Passwort"
              name="newPassword"
              #newPassword="ngModel"
              [(ngModel)]="userData.newPassword"
              required
              minlength="8"
              [class.is-invalid]="newPassword.invalid && newPassword.touched"
            ></ion-input>
            <div *ngIf="newPassword.invalid && newPassword.touched" class="invalid-feedback">
              <div *ngIf="newPassword.errors?.required">Das neue Passwort wird benötigt</div>
              <div *ngIf="newPassword.errors?.minlength">Das Passwort muss mindestens 8 Zeichen lang sein</div>
            </div>
          </ion-item>

          <!-- Confirm New Password -->
          <ion-item class="ion-item-tint">
            <ion-label position="floating">Passwort bestätigen</ion-label>
            <ion-input
              type="password"
              placeholder="Passwort bestätigen"
              name="confirmPassword"
              #confirmPassword="ngModel"
              [(ngModel)]="userData.confirmPassword"
              required
              [class.is-invalid]="confirmPassword.invalid && confirmPassword.touched"
            ></ion-input>
            <div
              *ngIf="(confirmPassword.invalid && confirmPassword.touched) || passwordMismatch"
              class="invalid-feedback"
            >
              <div *ngIf="confirmPassword.errors?.required">Bitte bestätigen Sie Ihr neues Passwort</div>
              <div *ngIf="passwordMismatch">Die Passwörter stimmen nicht überein.</div>
            </div>
          </ion-item>
        </ion-list>
      </ion-card>
    </form>

    <ion-button expand="block" color="secondary" style="margin: 15px 20px" (click)="submitChangePassword(form)">
      Passwort ändern
    </ion-button>
  </ion-grid>
</ion-content>
