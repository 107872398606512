// idGenerators.ts

// Importieren des 'customAlphabet' Moduls von 'nanoid', einem leichten ID-Generator
import { customAlphabet } from 'nanoid';

// Definition der Zeichensätze für die ID-Erzeugung
const charsLarge = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const charsSmall = 'abcdefghijklmnopqrstuvwxyz';
const charsNumbers = '0123456789';
const charsSpecial = '!#,?.-_';

/**
 * Generiert eine eindeutige ID mit einer Kombination aus großen und kleinen Buchstaben sowie Zahlen.
 * Die Standard-Länge der generierten ID ist 15 Zeichen.
 */
export const uuid = (size: number = 15) => {
  return customAlphabet(charsLarge + charsSmall + charsNumbers, size)();
};

/**
 * Generiert eine kürzere ID mit einer Kombination aus großen und kleinen Buchstaben.
 * Die Standard-Länge dieser ID ist 10 Zeichen.
 */
export const uuidChars = (size: number = 10) => {
  return customAlphabet(charsLarge + charsSmall, size)();
};

/**
 * Generiert eine Passwort-ähnliche ID, die auch Sonderzeichen enthält.
 * Die Standard-Länge dieser ID ist 15 Zeichen.
 */
export const uuidPass = (size: number = 15) => {
  return customAlphabet(charsLarge + charsSmall + charsNumbers + charsSpecial, size)();
};

/* 
   Beispiel für die Verwendung:
   import { uuid, uuidChars, uuidPass } from './idGenerators';

   class IhreKomponenteOderService {
     generateUuid() {
       console.log(uuid()); // generiert eine eindeutige ID
     }

     generateUuidChars() {
       console.log(uuidChars()); // generiert eine kürzere ID
     }

     generateUuidPass() {
       console.log(uuidPass()); // generiert eine Passwort-ähnliche ID
     }
   }
*/
