import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn
} from '@angular/fire/firestore';
import firebase from 'firebase';
import Horse, { Verbindung } from '../models/horse.model';
import { UserService } from './user.service';
import Analyse from '../models/analyse.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import User, { UserDataFb } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class HorseDbService {
  private storePath = '/horses';

  horsesRef: AngularFirestoreCollection<Horse>;
  user: User;

  constructor(private store: AngularFirestore, private userService: UserService) {
    this.horsesRef = store.collection(this.storePath);
  }

  getAllFromLoggedInUser(): AngularFirestoreCollection<Horse> {
    if (!this.user) return null;

    const query: QueryFn = (ref) => ref.where('ownerId', '==', this.user.authData.uid);
    return this.store.collection<Horse>(this.storePath, query);
  }

  /**
   * Liefert eine Liste von Pferden zurück, die entweder dem aktuell eingeloggten Benutzer gehören oder von ihm aboniert wurden.
   */
  getAllFromLoggedInUserInclViewed(): Observable<Horse[]> {
    // Holt den aktuellen Benutzer.
    return this.userService.getUser().pipe(
      switchMap((user) => {
        // Wenn es keine Benutzerinformationen gibt, wird null zurückgegeben.
        if (!user || !user.authData) return of(null);

        // Die Authentifizierungsdaten des Benutzers werden für spätere Verwendung gespeichert.
        this.user = user;

        // Definiert eine Abfrage, um Pferde zu finden, die dem Benutzer gehören.
        const ownedHorsesQuery: QueryFn = (ref) => ref.where('ownerId', '==', this.user.authData.uid);

        // Definiert eine Abfrage, um Pferde zu finden, die der Benutzer aboniert hat.
        const viewerHorsesQuery: QueryFn = (ref) => ref.where('viewerIds', 'array-contains', this.user.authData.uid);

        // Holt die Pferde basierend auf den obigen Abfragen.
        const ownedHorses = this.store.collection<Horse>(this.storePath, ownedHorsesQuery).snapshotChanges();
        const viewerHorses = this.store.collection<Horse>(this.storePath, viewerHorsesQuery).snapshotChanges();

        // Kombiniert die Ergebnisse der beiden Abfragen.
        return combineLatest([ownedHorses, viewerHorses]).pipe(
          map(([ownedHorsesSnap, viewerHorsesSnap]) => {
            // Wandelt die Snapshots in ein Array von Pferden um und ergänzt die erforderlichen Informationen.
            const ownedHorsesArray = ownedHorsesSnap.map((horseSnap) => ({
              id: horseSnap.payload.doc.id,
              ...horseSnap.payload.doc.data(),
              ownHorse: true
            }));
            const viewerHorsesArray = viewerHorsesSnap.map((horseSnap) => ({
              id: horseSnap.payload.doc.id,
              ...horseSnap.payload.doc.data(),
              ownHorse: false
            }));

            // Fügt die beiden Arrays zusammen zu einem Gesamtarray.
            return [...ownedHorsesArray, ...viewerHorsesArray];
          })
        );
      })
    );
  }

  addViewerToHorse(horseId: string): Promise<void | Error> {
    if (!this.user) {
      return Promise.reject(new Error('Actual user must be logged in'));
    }
    if (!horseId) {
      return Promise.reject(new Error('Horse is not available'));
    }

    const docRef = this.store.collection(this.storePath).doc(horseId);

    // Verwenden von subscribe, da wir mit einem Observable arbeiten
    return new Promise((resolve, reject) => {
      docRef
        .snapshotChanges()
        .pipe(take(1))
        .subscribe((docSnapshot) => {
          if (!docSnapshot.payload.exists) {
            reject(new Error('Cannot find the specified horse'));
            return;
          }

          // Aktuelle Daten des Dokuments holen
          const data = docSnapshot.payload.data() as Horse;
          const currentViewers = data.viewerIds || [];
          const currentVerbindungen = data.verbindungen || [];

          // Überprüfen, ob der Benutzer bereits ein Betrachter ist
          if (currentViewers.includes(this.user.authData.uid)) {
            reject(new Error('Pferd ist bereits verknüpft.'));
            return;
          }

          // Füge den aktuellen Benutzer zu den viewerIds und verbindungen hinzu
          const updateData = {
            viewerIds: firebase.firestore.FieldValue.arrayUnion(this.user.authData.uid),
            viewerCount: firebase.firestore.FieldValue.increment(1)
          };

          // Wenn der Benutzer noch nicht in verbindungen vorhanden ist, füge ihn hinzu
          if (!currentVerbindungen.some((v) => v.id === this.user.authData.uid)) {
            updateData['verbindungen'] = firebase.firestore.FieldValue.arrayUnion({
              id: this.user.authData.uid,
              vorname: this.user.dbData?.vorname,
              nachname: this.user.dbData?.nachname,
              role: 'viewer'
            });
          }

          // Führe das Update aus
          docRef
            .set(updateData, { merge: true })
            .then(resolve)
            .catch((error) => {
              console.error('Error adding viewer to horse:', error);
              reject(new Error('Could not add viewer to horse'));
            });
        }, reject);
    });
  }

  deleteViewer(horseId: string, viewer: Verbindung): Promise<void> {
    if (!horseId || !viewer.id) {
      return Promise.reject(new Error('Ungültiges Pferd oder Betrachter'));
    }

    const docRef = this.store.collection(this.storePath).doc(horseId);

    return docRef.get().toPromise().then((doc) => {
      if (!doc.exists) {
        throw new Error('Das Pferd wurde nicht gefunden');
      }

      const horse = doc.data() as Horse;
      const viewerIds = horse.viewerIds.filter(vId => vId !== viewer.id);
      const verbindungen = horse.verbindungen.filter(v => v.id !== viewer.id);

      return docRef.update({
        viewerIds: viewerIds,
        verbindungen: verbindungen,
      });
    });
  }

  transferHorse(horseId: string, viewer: Verbindung, addOldOwnerAsViewer: boolean = false): Promise<void> {
    return this.deleteViewer(horseId, viewer)
      .then(() => {
        const docRef = this.store.collection(this.storePath).doc(horseId);

        return docRef.get().toPromise().then((doc) => {
          if (!doc.exists) {
            throw new Error('Das Pferd wurde nicht gefunden');
          }

          const oldOwnerId = this.user.authData.uid;

          // Update horse information with the new owner
          const updateData: any = {
            ownerId: viewer.id,
            ownerName: `${viewer.vorname || ''} ${viewer.nachname || ''}`.trim(),
            ownHorse: true,
            owner: {
              vorname: viewer.vorname,
              nachname: viewer.nachname
            }
            // Hier könnten Sie weitere Felder aktualisieren, z.B. ownerEmail, falls erforderlich
          };

          if (addOldOwnerAsViewer) {
            // Stelle sicher, dass der alte Eigentümer nicht der aktuelle Benutzer ist, der wieder zum Eigentümer wird
            if (oldOwnerId !== viewer.id) {
              updateData.viewerIds = firebase.firestore.FieldValue.arrayUnion(oldOwnerId);
              updateData.verbindungen = firebase.firestore.FieldValue.arrayUnion({
                id: oldOwnerId,
                vorname: this.user.dbData?.vorname,
                nachname: this.user.dbData?.nachname,
                role: 'viewer'
              });
            }
          }

          return docRef.update(updateData);
        });
      });
  }

  getAll(): AngularFirestoreCollection<Horse> {
    return this.horsesRef;
  }

  get(id: string): AngularFirestoreDocument<Horse> {
    return this.horsesRef.doc(id);
  }

  // getHorseById(horseId: string): AngularFirestoreDocument<Horse> {
  //   if (!this.actualUser || !horseId) return null;
  //   return this.store.doc<Horse>(`${this.storePath}/${horseId}`);
  // }

  create(horse: Horse): any {
    horse.createdDate = firebase.firestore.FieldValue.serverTimestamp();
    return this.horsesRef.add({ ...horse, ownerId: this.user.authData.uid });
  }

  update(id: string, data: any): Promise<void> {
    data.updatedDate = firebase.firestore.FieldValue.serverTimestamp();
    return this.horsesRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {
    return this.horsesRef.doc(id).delete();
  }

  setStatusAnalyse(horseId: string, analyse: Analyse): Promise<void> {
    return this.get(horseId)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          const horse = doc.data() as Horse;
          const analyseToUpdate = horse.videoAnalysen.find(
            (analyseDb) => analyseDb.datumOrdner === analyse.datumOrdner
          );
          if (analyseToUpdate) {
            analyseToUpdate.videoAnalyse.freigabe = analyse.videoAnalyse.freigabe;
            analyseToUpdate.videoAnalyse.freigabeDatum = analyse.videoAnalyse.freigabeDatum;
            analyseToUpdate.videoAnalyse.analyseArten = analyse.videoAnalyse.analyseArten;
            return this.update(horseId, { videoAnalysen: horse.videoAnalysen });
          }
          throw new Error(`Analyse with datumOrdner ${analyse.datumOrdner} not found.`);
        }
        throw new Error(`Horse with ID ${horseId} not found.`);
      });
  }

  /**
   * Methode zum Abbrechen einer Analyse und Löschen der zugehörigen Daten.
   *
   * @param horseId - Die ID des Pferdes.
   * @param datumOrdner - Das Datum der zu löschenden Analyse.
   * @return Promise<void>
   */
  cancelUpload(horseId: string, datumOrdner: string): Promise<void> {
    // Erhalte eine Referenz auf das Firestore-Dokument mit der gegebenen horseId.
    return this.get(horseId)
      .ref.get()
      .then((doc) => {
        // Überprüfen, ob das Dokument existiert.
        if (doc.exists) {
          const horse = doc.data() as Horse;

          // Versuche, den Analyse-Eintrag aus videoAnalysen zu entfernen, basierend auf dem gegebenen datumOrdner.
          const updatedVideoAnalysen = horse.videoAnalysen.filter((analyseDb) => analyseDb.datumOrdner !== datumOrdner);

          const changes: any = {};

          // Wenn die Größe des aktualisierten videoAnalysen-Arrays kleiner ist als das ursprüngliche,
          // dann gab es eine Änderung und diese sollte in der Datenbank aktualisiert werden.
          if (updatedVideoAnalysen.length !== horse.videoAnalysen.length) {
            changes.videoAnalysen = updatedVideoAnalysen;
          }

          // Überprüfe und lösche den Eintrag aus videoDaten basierend auf dem gegebenen datumOrdner.
          const videoDatenKey = `${datumOrdner}`;
          if (horse.videoDaten && horse.videoDaten[videoDatenKey]) {
            delete horse.videoDaten[videoDatenKey];
            changes.videoDaten = horse.videoDaten;
          }

          // Wenn es Änderungen gibt, aktualisiere das Firestore-Dokument.
          if (Object.keys(changes).length > 0) {
            return this.update(horseId, changes);
          } else {
            // Werfe einen Fehler, wenn keine Änderungen erkannt wurden.
            console.error(`No changes detected for datumOrdner: ${datumOrdner}.`);
          }
        } else {
          // Wenn das Dokument nicht existiert, werfe einen Fehler.
          console.error(`Horse with ID ${horseId} not found.`);
        }
      });
  }

  updateAllHorsesFromUserWithFields(userId: string, updateFields: Partial<Horse>): Promise<void> {
    const batch = this.store.firestore.batch();

    // Pferde mit passender ownerId abrufen
    return this.store
      .collection(this.storePath, (ref) => ref.where('ownerId', '==', userId))
      .get()
      .toPromise()
      .then((snapshot) => {
        // Durch jedes Dokument iterieren und es zum Batch für das Update hinzufügen
        snapshot.forEach((doc) => {
          const horseRef = this.store.doc<Horse>(`${this.storePath}/${doc.id}`).ref;
          batch.update(horseRef, updateFields);
        });

        // Commit the batch
        return batch.commit();
      });
  }

  updateAllHorsesFromUserWithOwnerName(userId: string, OwnerName: string): Promise<void> {
    return this.updateAllHorsesFromUserWithFields(userId, {
      ownerName: OwnerName
    });
  }
}
