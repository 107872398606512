import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { UserDataDb, userRole } from '../models/user.model';
import { uuid } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class UserDbService {
  private storePath = '/users';

  usersRef: AngularFirestoreCollection<UserDataDb>;

  constructor(private store: AngularFirestore) {
    this.usersRef = store.collection(this.storePath);
  }

  getAll(): AngularFirestoreCollection<UserDataDb> {
    return this.usersRef;
  }

  get(id: string): AngularFirestoreDocument<UserDataDb> {
    return this.usersRef.doc(id);
  }

  create(id: string, user: UserDataDb): any {
    user.createdDate = firebase.firestore.FieldValue.serverTimestamp();

    // Creating a document reference with user.uid as the document ID
    const userDocRef = this.usersRef.doc(id);

    // Set the user data in the document
    return userDocRef.set(user);
  }

  createWithoutId(user: UserDataDb): any {
    return this.usersRef.add(user);
  }

  update(id: string, user: UserDataDb): Promise<void> {
    user.updatedDate = firebase.firestore.FieldValue.serverTimestamp();
    return this.usersRef.doc(id).update(user);
  }

  delete(id: string): Promise<void> {
    return this.usersRef.doc(id).delete();
  }

  getUserByUserCode(userCode: string): Promise<string | null> {
    return this.store.collection('users', ref => ref.where('userCode', '==', userCode))
      .get()
      .toPromise()
      .then(snapshot => {
        if (!snapshot.empty) {
          // Nehmen Sie die ID des ersten Dokuments, das der Bedingung entspricht
          return snapshot.docs[0].id;
        }
        return null;
      });
  }

  /**
   * Weist Nutzern, die noch keinen userCode oder keine Rolle haben,
   * jeweils einen zufälligen 10-stelligen Code und die Rolle 'nutzer' zu.
   */
  public assignToAllUsers(): void {
    this.usersRef.get().subscribe((snapshot) => {
      snapshot.forEach((doc) => {
        const user = doc.data() as UserDataDb;
        let changes = {};

        if (!user.userCode) {
          changes['userCode'] = uuid(15);
        }

        if (!user.role) {
          changes['role'] = 'nutzer' as userRole;
        }

        // Speichert nur, wenn mindestens eines der Properties gesetzt wurde
        if (Object.keys(changes).length > 0) {
          this.usersRef.doc(doc.id).update(changes);
        }
      });
    });
  }
}
