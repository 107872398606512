import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
// import { SwUpdate } from '@angular/service-worker';
import { MenuController, ModalController, Platform, ToastController } from '@ionic/angular';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Storage } from '@ionic/storage';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { UserDataDb } from './models/user.model';
import { UserFormModalComponent } from './components/user-form-modal/user-form-modal.component';
import { buildInfo, version } from '../environments/version';
import { environment } from '../environments/environment';
import { DialogService } from './services/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Pferde',
      url: '/horses',
      icon: 'horse'
    },
    {
      title: 'Analysen',
      url: '/analysis',
      icon: 'analytics'
    },
    {
      title: 'Hochladen',
      url: '/upload',
      icon: 'cloud-upload'
    }
  ];
  // loggedIn = false;
  dark = false;

  user: UserDataDb;
  profileImageUrl: string;
  version = version;
  buildInfo = buildInfo;
  environmentName = environment.name;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private modalCtrl: ModalController,
    public authService: AuthenticationService,
    private userService: UserService,
    private dialogService: DialogService
  ) // private userData: UserData,
  // private swUpdate: SwUpdate,
  // private toastCtrl: ToastController,
  {
    this.initializeApp();
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.user = user?.dbData;
      this.profileImageUrl = this.user?.profileImageUrl;
    });
  }

  async openModalPersonalData() {
    const modal = await this.modalCtrl.create({
      component: UserFormModalComponent
    });

    modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      // use the data here
    }
  }

  // async ngOnInit() {
  //   // this.checkLoginStatus();
  //   // this.listenForLoginEvents();

  //   this.swUpdate.available.subscribe(async res => {
  //     const toast = await this.toastCtrl.create({
  //       message: 'Ein Update ist verfübar!',
  //       position: 'bottom',
  //       buttons: [
  //         {
  //           role: 'cancel',
  //           text: 'Reload'
  //         }
  //       ]
  //     });

  //     await toast.present();

  //     toast
  //       .onDidDismiss()
  //       .then(() => this.swUpdate.activateUpdate())
  //       .then(() => window.location.reload());
  //   });
  // }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        StatusBar.hide();
        SplashScreen.hide();
      }
    });
  }

  // checkLoginStatus() {
  //   return this.userData.isLoggedIn().then(loggedIn => {
  //     return this.updateLoggedInStatus(loggedIn);
  //   });
  // }

  // updateLoggedInStatus(loggedIn: boolean) {
  //   setTimeout(() => {
  //     this.loggedIn = loggedIn;
  //   }, 300);
  // }

  // listenForLoginEvents() {
  //   window.addEventListener('user:login', () => {
  //     this.updateLoggedInStatus(true);
  //   });

  //   window.addEventListener('user:signup', () => {
  //     this.updateLoggedInStatus(true);
  //   });

  //   window.addEventListener('user:logout', () => {
  //     this.updateLoggedInStatus(false);
  //   });
  // }

  // logout() {
  //   this.userData.logout().then(() => {
  //     return this.router.navigateByUrl('/login');
  //   });
  // }

  signOut() {
    this.dialogService.presentQuestion(
      'Möchten Sie sich abmelden?',
      async () => {
        this.authService.SignOut();
      },
      'Abmelden',
      null,
      'Ja',
      'Nein'
    );
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

  onVersionClick(event: MouseEvent) {
    console.log(event)
    if (event.altKey) {
      // Führe assignToAllUsers() aus, wenn die Strg-Taste gedrückt gehalten wird
      // this.userService.assignToAllUsers();
    }
  }
}
