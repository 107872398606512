import { Injectable, NgZone } from '@angular/core';
// import * as auth from "firebase/auth";
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UserDataDb } from '../models/user.model';
import { UserService } from './user.service';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { UserDbService } from './users.db.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user$: Observable<firebase.User>;

  constructor(
    public store: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private userService: UserService,
    private userDbService: UserDbService,
    public router: Router,
    public ngZone: NgZone
  ) {
    this.user$ = this.fireAuth.authState;
    this.user$.subscribe((user) => {
      if (user) {
        this.userService.setUser(user);
        // this.router.navigateByUrl("/horses");
      } else {
        this.userService.setUser(null);
        // this.router.navigateByUrl('/login');
      }
    });
  }

  // Returns true when user is logged in
  get isLoggedIn(): boolean {
    const user = this.getUserLocalStorage();
    return user !== null && user?.emailVerified !== false ? true : false;
  }
  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = this.getUserLocalStorage();
    return user?.emailVerified !== false ? true : false;
  }

  getUser() {
    return this.user$;
  }

  getUserLocalStorage(): firebase.User {
    return JSON.parse(localStorage.getItem('user'));
  }

  removeUserLocalStorage() {
    localStorage.removeItem('user');
  }

  // Login in with email/password
  SignIn(email, password) {
    return this.fireAuth.signInWithEmailAndPassword(email, password).catch((error) => {
      this.throwAuthError(error?.code, error?.message, error);
    });
  }
  // Register user with email/password
  RegisterUser(email, password, vorname, nachname, referralUserId): Promise<any> {
    return this.fireAuth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // User registration successful

        const user = userCredential.user;
        const referredByName = `${vorname} ${nachname}`;

        // Create the user in the Firestore collection
        return this.userDbService.create(user.uid, {
          email: user.email,
          vorname,
          nachname,
          referredBy: referralUserId,
          referredByName
        } as UserDataDb);
      })
      .catch((error) => {
        this.throwAuthError(error?.code, error?.message, error);
      });
  }

  // Email verification when new user register
  SendVerificationMail() {
    return this.fireAuth.currentUser
      .then((user) => {
        return user
          .sendEmailVerification()
          .then(() => {
            // E-Mail-Verifizierung erfolgreich versendet
            // this.router.navigate(["login"]);
          })
          .catch((error) => {
            this.throwAuthError(error?.code, error?.message, error);
          });
      })
      .catch((error) => {
        // Fehler beim Abrufen des aktuellen Benutzers
        throw new Error('Fehler beim Abrufen des aktuellen Benutzers: ' + error.message);
      });
  }

  // Recover password
  SendPasswordResetEmail(passwordResetEmail) {
    return this.fireAuth.sendPasswordResetEmail(passwordResetEmail).catch((error) => {
      this.throwAuthError(error?.code, error?.message, error);
    });
  }

  // Sign in with Gmail
  GoogleAuth() {
    // return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  // Auth providers
  AuthLogin(provider) {
    return this.fireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Store user in localStorage
  SetUserData(user) {
    return;
    const userRef: AngularFirestoreDocument<any> = this.store.doc(`users`);
    const userData: UserDataDb = {
      id: user.id,
      email: user.email,
      displayName: user.displayName,
      profileImageUrl: user.profileImageUrl,
      emailVerified: user.emailVerified,
      vorname: user.vorname,
      nachname: user.nachname,
      adresse: user.adresse,
      createdDate: undefined
    };
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign-out
  SignOut() {
    return this.fireAuth.signOut().then(() => {
      this.removeUserLocalStorage();
      this.router.navigateByUrl('/login');
    });
  }

  // Change password
  ChangePassword(oldPassword: string, newPassword: string): Promise<void> {
    return this.fireAuth.currentUser.then((user) => {
      // First, re-authenticate the user
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
      return user
        .reauthenticateWithCredential(credential)
        .then(() => {
          // Now change the password
          return user.updatePassword(newPassword);
        })
        .catch((error) => {
          this.throwAuthError(error?.code, error?.message, error);
        });
    });
  }

  // Change the authenticated user's email
  ChangeEmail(currentPassword: string, newEmail: string): Promise<void> {
    return this.fireAuth.currentUser
      .then((user) => {
        // Re-authenticate the user first
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        return user.reauthenticateWithCredential(credential).then(() => {
          // Once re-authenticated, update the email
          return user.updateEmail(newEmail);
        });
      })
      .catch((error) => {
        this.throwAuthError(error?.code, error?.message, error);
      });
  }

  private throwAuthError(errorCode: any, message: any, errorMessage: any) {
    switch (errorCode) {
      case 'auth/wrong-password':
        message = '<p>Falsches Passwort.</p>Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.';
        break;
      case 'auth/user-disabled':
        message = '<p>Dieser Nutzer wurde deaktiviert.</p>Bitte wenden Sie sich an den Support.';
        break;
      case 'auth/invalid-email':
        message =
          '<p>Die angegebene E-Mail-Adresse ist ungültig.</p>Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.';
        break;
      case 'auth/user-not-found':
        message = '<p>Nutzer nicht gefunden.</p>Bitte überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort.';
        break;
      case 'auth/email-already-in-use':
        message =
          '<p>Die angegebene E-Mail-Adresse wird bereits von einem anderen Nutzer verwendet.</p>Bitte verwenden Sie eine andere E-Mail-Adresse.';
        break;
      case 'auth/operation-not-allowed':
        message = 'Die angeforderte Authentifizierungsmethode ist für das Firebase-Projekt nicht aktiviert.';
        break;
      case 'auth/too-many-requests':
        message =
          '<p>Zu viele Anfragen von dieser Anwendung gesendet.</p>Versuchen Sie es später erneut oder kontaktieren Sie den Support.';
        break;
      case 'auth/account-exists-with-different-credential':
        message = 'Ein Konto mit derselben E-Mail-Adresse, aber mit einer anderen Anmeldemethode existiert bereits.';
        break;
      case 'auth/auth-domain-config-required':
        message = 'Die Authentifizierungsdomänenkonfiguration ist erforderlich.';
        break;
      default:
        message = errorMessage;
    }

    throw new Error(message);
  }
}
