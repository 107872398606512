// stringManipulation.ts

/**
 * Wandelt große Zahlen in ein kürzeres Format um (z.B. 1000 zu 1k).
 * @param num - Die umzuwandelnde Zahl.
 */
export const kFormatter = (num: number): string => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num.toString());

/**
 * Konvertiert HTML-String in einen normalen Textstring, indem HTML-Tags entfernt werden.
 * @param html - Der HTML-String, der konvertiert werden soll.
 */
export const htmlToString = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, '');

/**
 * Kapitalisiert das erste Zeichen eines Wortes.
 * @param word - Das Wort, dessen erstes Zeichen kapitalisiert werden soll.
 */
export const capitalize = (word: string): string => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

/* 
   Beispiel für die Verwendung:
   import { kFormatter, htmlToString, capitalize } from './stringManipulation';

   class IhreKomponenteOderService {
     testZahl = 1234;
     testHtml = '<p>Beispieltext</p>';
     testWort = 'beispiel';

     showKFormatted() {
       console.log(kFormatter(this.testZahl)); // gibt '1.2k' aus
     }

     showHtmlConverted() {
       console.log(htmlToString(this.testHtml)); // gibt 'Beispieltext' ohne HTML-Tags aus
     }

     showCapitalized() {
       console.log(capitalize(this.testWort)); // gibt 'Beispiel' aus
     }
   }
*/
