// objectArrayManipulation.ts

/**
 * Aktualisiert eine verschachtelte Eigenschaft eines Objekts.
 * @param obj - Das Zielobjekt.
 * @param propPath - Der Pfad zur Eigenschaft als String, getrennt durch Punkte.
 * @param value - Der neue Wert, der gesetzt werden soll.
 */
export const updateNestedProp = (obj: any, propPath: string, value: any) => {
  if (value === undefined) return;

  const [head, ...rest] = propPath.split('.');

  !rest.length ? (obj[head] = value) : updateNestedProp(obj[head], rest.join('.'), value);
};

/**
 * Sortiert ein Array von Objekten basierend auf einer verschachtelten Eigenschaft.
 * @param arr - Das Array von Objekten.
 * @param propPath - Der Pfad zur verschachtelten Eigenschaft, getrennt durch Punkte.
 */
export const sortNestedProp = (arr: any[], propPath: string) => {
  const props = propPath.split('.');
  const len = props.length;

  arr.sort((a, b) => {
    var i = 0;
    var valA = a;
    var valB = b;

    while (i < len) {
      valA = valA[props[i]];
      valB = valB[props[i]];
      i++;
    }

    if (!valA) return 1;
    if (!valB) return -1;

    return valA.localeCompare(valB, 'de', { sensitivity: 'base' });
  });

  return arr;
};

/**
 * Sortiert ein Array von Objekten basierend auf mehreren verschachtelten Eigenschaften.
 * @param arr - Das Array von Objekten.
 * @param props - Ein Array von Objekten, die Sortierkriterien enthalten.
 */
export const sortUtil = (arr: any[], props: any[]) => {
  arr.sort((a, b) => {
    const obj1 = a,
      obj2 = b;

    let i;
    let len;

    for (let index = 0; index < props.length; index++) {
      const prop = props[index];
      const propNext = index < props.length ? props[index + 1] : null;

      if (index > 0) {
        a = obj1;
        b = obj2;
      }

      prop.sortColNested = prop.sortCol.split('.');
      len = prop.sortColNested.length;
      i = 0;

      while (i < len) {
        a = a[prop.sortColNested[i]];
        b = b[prop.sortColNested[i]];
        i++;
      }

      if (a == undefined || a == null) return 1;
      if (b == undefined || b == null) return -1;
      if (typeof a != 'string') a = '' + a;
      if (typeof b != 'string') b = '' + b;

      let comp =
        prop.sortOrder === 'asc'
          ? a.localeCompare(b, 'de', { sensitivity: 'base' })
          : b.localeCompare(a, 'de', { sensitivity: 'base' });

      if (comp || !propNext) return comp;
    }
  });
  return arr;
};

/* 
   Beispiel für die Verwendung:
   import { updateNestedProp, sortNestedProp, sortUtil } from './objectArrayManipulation';

   class IhreKomponenteOderService {
     obj = { name: { first: 'Max', last: 'Mustermann' } };
     arr = [{ name: { first: 'Max', last: 'Mustermann' } }, { name: { first: 'Anna', last: 'Schmidt' } }];

     updateProperty() {
       updateNestedProp(this.obj, 'name.first', 'Maximilian');
       console.log(this.obj); // aktualisiert den Vornamen zu 'Maximilian'
     }

     sortArray() {
       sortNestedProp(this.arr, 'name.last');
       console.log(this.arr); // sortiert das Array basierend auf dem Nachnamen
     }

     sortArrayMultipleProps() {
       const sortCriteria = [{ sortCol: 'name.last', sortOrder: 'asc' }, { sortCol: 'name.first', sortOrder: 'asc' }];
       sortUtil(this.arr, sortCriteria);
       console.log(this.arr); // sortiert das Array basierend auf Nachname und dann Vorname
     }
   }
*/
