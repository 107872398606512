import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, forkJoin, from } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import FileData from '../models/file-data.model';
import { VideoDbService } from './video.db.service';
import Horse from '../models/horse.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private basePath = '';
  private basePathUser = '_User';
  private folderOriginale = 'Originale';
  private folderAnalyse = 'Analysen';
  private folderAuswertungen = 'Auswertungen';
  private folderRohdaten = 'Rohdaten';
  private fileNameDummy = '.';

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private videoService: VideoDbService
  ) {}

  toShortFileDate(date: Date) {
    const day = date.getDate().toString().padStart(2, '0'); // extract day component and pad with leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // extract month component (note: month is zero-based) and pad with leading zero if necessary
    const year = date.getFullYear().toString(); // extract year component

    return `${year}-${month}-${day}`;
  }

  getFolderPathForHorse(horseId: string, horseAlias: string): string {
    return `${this.basePath}/${horseAlias}_${horseId}`;
  }

  getFolderPathForHorseAnalysis(horseId: string, horseAlias: string, datumOrdner: string): string {
    return `${this.getFolderPathForHorse(horseId, horseAlias)}/${datumOrdner}`;
  }

  async createFileName(videoData: FileData, datumOrdner: string) {
    const pathForHorseAnalysis = this.getFolderPathForHorseAnalysis(
      videoData.horseId,
      videoData.horseAlias,
      datumOrdner
    );
    let fileNameBase = `${videoData.inhalt.gang}-${videoData.inhalt.richtung}-${videoData.inhalt.reiter}_${videoData.horseAlias}`;
    let fileName = `${fileNameBase}_${videoData.file.name}`;
    let filePath = `${pathForHorseAnalysis}/${this.folderOriginale}/${fileName}`;

    // Check if the filename already exists
    let count = 2;
    while (await this.checkFileExists(filePath)) {
      const dotIndex = videoData.file.name.lastIndexOf('.');
      const fileExtension = videoData.file.name.substring(dotIndex + 1);
      const fileNameWithoutExtension = videoData.file.name.substring(0, dotIndex);

      fileName = `${fileNameBase}_${fileNameWithoutExtension}_${count}.${fileExtension}`;
      const newFilePath = `${pathForHorseAnalysis}/${this.folderOriginale}/${fileName}`;
      count++;
      filePath = newFilePath;
    }

    return filePath;
  }

  getFileExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  async checkFileExists(filePath: string): Promise<boolean> {
    try {
      await this.storage
        .ref(filePath)
        .getMetadata()
        .pipe(
          tap((data) => {
            // console.log(data);
          }),
          take(1)
        )
        .toPromise();
      // File exists
      return true;
    } catch (error) {
      // File does not exist
      return false;
    }
  }

  async checkFileExistsFromUrl(url: string): Promise<boolean> {
    try {
      await this.storage
        .refFromURL(url)
        .getMetadata()
        .pipe(
          tap((data) => {
            // console.log(data);
          }),
          take(1)
        )
        .toPromise();
      // File exists
      return true;
    } catch (error) {
      // File does not exist
      return false;
    }
  }

  addVideo(videoData: FileData, datumOrdner: string, isFirstVideoData: boolean): Observable<number | undefined> {
    return new Observable<number | undefined>((observer) => {
      this.createFileName(videoData, datumOrdner)
        .then((filePath) => {
          const storageRef = this.storage.ref(filePath);
          const metadata = {
            contentEncoding: 'identity',
            customMetadata: {
              Art: `${videoData.inhalt.gang}-${videoData.inhalt.richtung}-${videoData.inhalt.reiter}`
            }
          };

          const uploadTask = this.storage.upload(filePath, videoData.file, metadata);

          uploadTask
            .snapshotChanges()
            .pipe(
              finalize(() => {
                storageRef.getDownloadURL().subscribe((downloadURL) => {
                  videoData.url = downloadURL;
                  videoData.filename = videoData.file.name;
                  this.addVideoDb(videoData, datumOrdner, isFirstVideoData);
                });
              })
            )
            .subscribe();

          uploadTask.percentageChanges().subscribe(
            (percentage) => {
              observer.next(percentage);
            },
            (error) => {
              observer.error(error);
            },
            () => {
              observer.complete();
            }
          );
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  addVideoDb(videoData: FileData, datumOrdner: string, isFirstVideoData: boolean): void {
    this.videoService.addVideo(videoData, datumOrdner, isFirstVideoData).then(() => {});
  }

  uploadHorseProfilePhoto(photoData: FileData): Observable<number | undefined> {
    const folderIdentifier = `${photoData.horseAlias}_${photoData.horseId}`;
    return this.uploadProfilePhoto(photoData, this.basePath, folderIdentifier);
  }

  uploadUserProfilePhoto(photoData: FileData): Observable<number | undefined> {
    return this.uploadProfilePhoto(photoData, this.basePathUser, photoData.userId.toString());
  }

  private uploadProfilePhoto(
    photoData: FileData,
    basePath: string,
    folderIdentifier: string
  ): Observable<number | undefined> {
    return new Observable<number | undefined>((observer) => {
      const fileExtension = this.getFileExtension(photoData.file.name);
      const fileName = `profileImage.${fileExtension}`;
      const filePath = `${basePath}/${folderIdentifier}/${fileName}`;

      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, photoData.file);

      uploadTask.percentageChanges().subscribe(
        (percentage) => {
          observer.next(percentage);
        },
        (error) => {
          observer.error(error);
        }
      );

      uploadTask
        .snapshotChanges()
        .pipe(
          finalize(() => {
            storageRef.getDownloadURL().subscribe(async (downloadURL) => {
              photoData.url = downloadURL;
              photoData.filename = fileName;
              photoData.fileExtension = fileExtension;
              observer.complete();
            });
          })
        )
        .subscribe();
    });
  }

  createFileFolder(horse: Horse, datumOrdner: string): Promise<void[]> {
    const pathForHorseAnalysis = this.getFolderPathForHorseAnalysis(horse.id, horse.alias, datumOrdner);

    const paths = [
      { folder: `${this.folderAnalyse}/`, fileName: this.fileNameDummy },
      { folder: `${this.folderAuswertungen}/`, fileName: this.fileNameDummy },
      { folder: `${this.folderRohdaten}/`, fileName: this.fileNameDummy }
    ];

    const uploadTasks = paths.map((path) => {
      const filePath = `${pathForHorseAnalysis}/${path.folder}${path.fileName}`;
      return this.storage.upload(filePath, {}).then();
    });

    return Promise.all(uploadTasks);
  }

  async deleteVideo(videoData: FileData) {
    try {
      await this.deleteVideoDb(videoData);
    } catch (error) {
      console.error('Error on deleteVideoDb: ' + error);
      return error;
    }

    try {
      await this.storage.storage.refFromURL(videoData.url).delete();
      return true;
    } catch (error) {
      console.error('Error on deleteVideo: ' + error);
      return error;
    }
  }

  deleteVideoDb(videoData: FileData): Promise<void> {
    return this.videoService.deleteVideo(videoData);
  }

  /**
   * Deletes all files and folders within a specific Firebase Storage path.
   *
   * @param path - The path in Firebase Storage.
   */
  deleteFilesInFolderPath(path: string): Promise<void> {
    const storageRef = this.storage.ref(path);

    return storageRef
      .listAll()
      .toPromise()
      .then((result) => {
        // Create an array of file deletion promises
        const fileDeletionPromises = result.items.map((fileRef) => fileRef.delete());

        // Create an array of folder deletion promises
        const folderDeletionPromises = result.prefixes.map((folderRef) =>
          this.deleteFilesInFolderPath(folderRef.fullPath)
        );

        // Execute all deletion promises
        return Promise.all([...fileDeletionPromises, ...folderDeletionPromises]);
      })
      .then(() => void 0);
  }

  private saveFileData(fileUpload: FileData): void {
    this.db.list(this.basePath).push(fileUpload);
  }

  getFiles(numberItems: number): AngularFireList<FileData> {
    return this.db.list(this.basePath, (ref) => ref.limitToLast(numberItems));
  }

  listFiles(horseAlias, horseId, datumOrdner, folder): Observable<FileData[]> {
    let folderPath = `${this.getFolderPathForHorseAnalysis(horseId, horseAlias, datumOrdner)}/${folder}`;
    const storageRef = this.storage.ref(folderPath);

    return storageRef.listAll().pipe(
      switchMap((result) => {
        const observables = result.items
          .filter((item) => item.name !== this.fileNameDummy)
          .map((item) => {
            return from(item.getDownloadURL()).pipe(
              switchMap((url) => {
                return from(item.getMetadata()).pipe(
                  map((metadata) => {
                    const videoData = new FileData();
                    videoData.url = url;
                    videoData.uploadDate = metadata.timeCreated;
                    videoData.uploadDateIso = new Date(metadata.timeCreated).toISOString();
                    videoData.filename = item.name;
                    videoData.metadata = {
                      size: metadata.size,
                      type: metadata.contentType,
                      lastModified: metadata.updated,
                      lastModifiedDate: new Date(metadata.updated)
                    };
                    videoData.horseId = horseId;
                    videoData.horseAlias = horseAlias;

                    return videoData;
                  })
                );
              })
            );
          });

        return forkJoin(observables);
      })
    );
  }

  getFileData(
    horseAlias: string,
    horseId: string,
    datumOrdner: string,
    fileName: string,
    folder?: string
  ): Observable<FileData> {
    let filePath = `${this.getFolderPathForHorseAnalysis(horseId, horseAlias, datumOrdner)}`;
    if (folder) {
      filePath += `/${folder}`;
    }
    filePath += `/${fileName}`;

    const storageRef = this.storage.ref(filePath);

    return storageRef.getDownloadURL().pipe(
      switchMap((url) => {
        return storageRef.getMetadata().pipe(
          map((metadata) => {
            const fileData = new FileData();
            fileData.url = url;
            fileData.uploadDate = metadata.timeCreated;
            fileData.uploadDateIso = new Date(metadata.timeCreated).toISOString();
            fileData.filename = metadata.name;
            fileData.metadata = {
              size: metadata.size,
              type: metadata.contentType,
              lastModified: metadata.updated,
              lastModifiedDate: new Date(metadata.updated)
            };
            fileData.horseId = horseId;
            fileData.horseAlias = horseAlias;

            return fileData;
          })
        );
      })
    );
  }

  listFilesAsUrls(horseAlias, horseId, datumOrdner, folder) {
    let folderPath = `${this.getFolderPathForHorseAnalysis(horseId, horseAlias, datumOrdner)}/${folder}`;
    const storageRef = this.storage.ref(folderPath);

    return storageRef.listAll().pipe(
      switchMap((result) => {
        const observables = result.items.map((item) => {
          console.log(item.name);
          return from(item.getDownloadURL());
        });

        return forkJoin(observables);
      }),
      map((urls) => {
        return urls.filter((url) => url !== null);
      })
    );
  }

  listFilesSimple(horseAlias, horseId, datumOrdner, folder) {
    let folderPath = `${this.getFolderPathForHorseAnalysis(horseId, horseAlias, datumOrdner)}/${folder}`;
    const storageRef = this.storage.ref(folderPath);

    storageRef.listAll().subscribe(
      (result) => {
        result.items.forEach((item) => {
          console.log(item.name);
          item.getDownloadURL().then((url) => {});
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // deleteFile(fileUpload: VideoData): void {
  //   this.deleteFileDatabase(fileUpload.key)
  //     .then(() => {
  //       this.deleteFileStorage(fileUpload.videoname);
  //     })
  //     .catch((error) => console.log(error));
  // }

  // private deleteFileDatabase(key: string): Promise<void> {
  //   return this.db.list(this.basePath).remove(key);
  // }

  // private deleteFileStorage(name: string): void {
  //   const storageRef = this.storage.ref(this.basePath);
  //   storageRef.child(name).delete();
  // }
}
