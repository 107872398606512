import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import FileData from '../models/file-data.model';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { DateUtils } from '../shared/date.utils';
import Analyse from '../models/analyse.model';

@Injectable({
  providedIn: 'root'
})
export class VideoDbService {
  private storePath = '/horses';

  videosDataRef: AngularFirestoreCollection<any>;

  constructor(
    store: AngularFirestore,
    private dateUtils: DateUtils
  ) {
    this.videosDataRef = store.collection(this.storePath);
  }

  getAll(): AngularFirestoreCollection<FileData> {
    return this.videosDataRef;
  }

  get(id: string): AngularFirestoreDocument<FileData> {
    return this.videosDataRef.doc(id);
  }

  addVideo(video: FileData, datumOrdner: string, isFirstVideoData: boolean): any {
    let { horseId, horseAlias: horseAlias, file, ...videoData } = video;

    let videoDaten = {};
    let videoDatenDateOrdner = `${datumOrdner}`;
    videoDaten[videoDatenDateOrdner] = firebase.firestore.FieldValue.arrayUnion({
      ...videoData
    });

    if (!isFirstVideoData) {
      return this.videosDataRef.doc(horseId).set({ videoDaten }, { merge: true });
    }

    let analyse: Analyse = {
      datumOrdner,
      videoAnalyse: {
        erstelltAm: new Date(),
        freigabe: false,
        erfolgt: false
      }
    };

    let videoAnalysen = firebase.firestore.FieldValue.arrayUnion(analyse);

    return this.videosDataRef.doc(horseId).set(
      {
        videoAnalysen,
        videoDaten
      },
      { merge: true }
    );
  }

  deleteVideo(video: FileData): Promise<void> {
    let { horseId, horseAlias: horseAlias, file, ...videoData } = video;

    let date = this.dateUtils.getDatePartAsFilename(new Date(videoData.uploadDateIso));

    let videoDaten = {};
    let videoDatenDateOrdner = `${date}`;
    videoDaten[videoDatenDateOrdner] = firebase.firestore.FieldValue.arrayRemove({
      ...videoData
    });

    return this.videosDataRef.doc(horseId).set(
      {
        videoDaten
      },
      { merge: true }
    );
  }

  update(id: string, data: any): Promise<void> {
    return this.videosDataRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {
    return this.videosDataRef.doc(id).delete();
  }
}
