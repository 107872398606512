// validation.ts

/**
 * Prüft, ob ein Objekt leer ist (keine eigenen Eigenschaften hat).
 * @param obj - Das zu überprüfende Objekt.
 * @returns true, wenn das Objekt leer ist, andernfalls false.
 */
export const isObjEmpty = (obj: Record<string, unknown>): boolean => Object.keys(obj).length === 0;

/**
 * Prüft, ob der übergebene Wert eine ganze Zahl ist.
 * @param value - Der zu überprüfende Wert.
 * @returns true, wenn der Wert eine ganze Zahl ist, andernfalls false.
 */
export const isInteger = (value: any): boolean => !isNaN(parseInt(value));

/**
 * Prüft, ob der übergebene Wert eine Fließkommazahl ist.
 * @param value - Der zu überprüfende Wert.
 * @returns true, wenn der Wert eine Fließkommazahl ist, andernfalls false.
 */
export const isFloat = (value: any): boolean => !isNaN(parseFloat(value));

/* 
   Beispiel für die Verwendung:
   import { isObjEmpty, isInteger, isFloat } from './validation';

   class IhreKomponenteOderService {
     testObj = {};
     testInt = '123';
     testFloat = '123.45';

     checkObjEmpty() {
       console.log(isObjEmpty(this.testObj)); // gibt true zurück, wenn testObj leer ist
     }

     checkIsInteger() {
       console.log(isInteger(this.testInt)); // gibt true zurück, wenn testInt eine ganze Zahl ist
     }

     checkIsFloat() {
       console.log(isFloat(this.testFloat)); // gibt true zurück, wenn testFloat eine Fließkommazahl ist
     }
   }
*/
