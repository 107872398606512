// numberFormatting.ts

/**
 * Formatiert eine Zahl als Prozentwert.
 * @param value - Die zu formatierende Zahl.
 * @param minFractionDigits - Minimale Anzahl an Nachkommastellen.
 * @param locale - Lokalisierungseinstellungen für die Formatierung.
 */
export const formatPercentage = (
  value: number,
  minFractionDigits = 1,
  locale: string = 'en-GB'
): string => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2
  }).format(value);
};

/**
 * Formatiert eine Zahl im Dezimalformat.
 * @param value - Die zu formatierende Zahl.
 * @param locale - Lokalisierungseinstellungen für die Formatierung.
 * @param minFractionDigits - Minimale Anzahl an Nachkommastellen.
 */
export const formatDecimal = (
  value: number,
  locale: string = 'de-DE',
  minFractionDigits = 1
): string => {
  return Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2
  }).format(value);
};

/* 
   Beispiel für die Verwendung:
   import { formatPercentage, formatDecimal } from './numberFormatting';

   class IhreKomponenteOderService {
     testZahl = 0.123;

     showFormattedPercentage() {
       console.log(formatPercentage(this.testZahl)); // gibt '12.3%' aus
     }

     showFormattedDecimal() {
       console.log(formatDecimal(this.testZahl)); // gibt '0,123' aus (abhängig von der Lokalisierung)
     }
   }
*/
