import { Component } from '@angular/core';
import { AlertController, IonicSafeString, ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';
import { NgForm } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-change-password-form-modal',
  templateUrl: './change-password-form-modal.component.html',
  styleUrls: ['./change-password-form-modal.component.scss']
})
export class ChangePasswordFormModalComponent {
  userData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  passwordMismatch: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private authService: AuthenticationService,
    private dialogService: DialogService
  ) {}

  // Dismiss the modal
  dismissPasswordModal() {
    this.modalCtrl.dismiss();
  }

  async submitChangePassword(form: NgForm) {
    this.passwordMismatch = false;

    if (!this.validateForm(form)) {
      return;
    }

    if (this.userData.newPassword !== this.userData.confirmPassword) {
      this.passwordMismatch = true;
      return;
    }

    try {
      this.loadingService.showLoading();
      await this.authService.ChangePassword(this.userData.oldPassword, this.userData.newPassword);
      this.dialogService.presentAlert('Passwort erfolgreich geändert.');
      this.dismissPasswordModal();
    } catch (error) {
      console.log(`Fehler beim Ändern des Passworts: ${error}`);
      this.dialogService.presentAlert(error.message);
    } finally {
      this.loadingService.hideLoading();
    }
  }

  validateForm(form: NgForm): boolean {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return false;
    }
    return true;
  }
}
