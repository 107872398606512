// dateFormatting.ts

/**
 * Formatiert ein Datum in einem benutzerfreundlichen Format.
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param value - Das zu formatierende Datum als String.
 * @param toTimeForCurrentDay - Wenn true, wird für das heutige Datum nur die Uhrzeit angezeigt.
 * @param formatting - Intl.DateTimeFormat Optionen für das Format.
 */
export const formatDate = (
  value: string,
  toTimeForCurrentDay = true,
  formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: '2-digit' }
): string => {
  if (!value) return value;

  const date = new Date(value);
  let time = false;
  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
    time = true;
  }

  let dateStr = new Intl.DateTimeFormat('de-DE', formatting).format(date);
  if (time) dateStr += ' Uhr';
  return dateStr;
};

/**
 * Formatiert ein Datum und eine Uhrzeit.
 * @param value - Das zu formatierende Datum als String.
 * @param formatting - Intl.DateTimeFormat Optionen für das Format.
 */
export const formatDateTime = (
  value: string,
  formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: '2-digit', hour: 'numeric', minute: 'numeric' }
): string => {
  if (!value) return value;
  return new Intl.DateTimeFormat('de-DE', formatting).format(new Date(value)) + ' Uhr';
};

/**
 * Formatiert nur die Uhrzeit.
 * @param value - Die zu formatierende Uhrzeit als String.
 * @param formatting - Intl.DateTimeFormat Optionen für das Format.
 */
export const formatTime = (
  value: string,
  formatting: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }
): string => {
  if (!value) return value;
  return new Intl.DateTimeFormat('de-DE', formatting).format(new Date(value)) + ' Uhr';
};

/**
 * Überprüft, ob das übergebene Datum heute ist.
 * @param date - Das zu überprüfende Datum.
 */
const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

/* 
   Beispiel für die Verwendung:
   import { formatDate, formatDateTime, formatTime } from './dateFormatting';

   class IhreKomponenteOderService {
     testDatum = '2023-03-15T08:30:00';

     showFormattedDate() {
       console.log(formatDate(this.testDatum)); // gibt das Datum in einem benutzerfreundlichen Format aus
     }

     showFormattedDateTime() {
       console.log(formatDateTime(this.testDatum)); // gibt das Datum und die Uhrzeit formatiert aus
     }

     showFormattedTime() {
       console.log(formatTime(this.testDatum)); // gibt nur die Uhrzeit formatiert aus
     }
   }
*/
