import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericFilter',
  pure: true // this is the flag to update the value when the object is changed
})
export class GenericFilterPipe implements PipeTransform {
  transform(items: any[], fields: string[], value: string): any[] {
    if (!items) return [];
    if (!fields || fields.length === 0 || !value) return items;

    // Durchsuchen Sie jedes Feld, und prüfen Sie, ob das Element in einem der Felder den Wert enthält
    return items.filter((it) => {
      for (let field of fields) {
        if (it[field]?.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }
}
