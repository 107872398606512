import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import firebase from 'firebase/app';
import { UserDbService } from './users.db.service';
import User, { UserDataFb } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private localStorageKey = 'user';
  private onDestroy = new Subject<void>();
  private userSubscription: Subscription;

  constructor(private userDbService: UserDbService) {
    const storedAuthUser = localStorage.getItem(this.localStorageKey);
    if (storedAuthUser) {
      const authUser: UserDataFb = JSON.parse(storedAuthUser);
      const user: User = {
        authData: authUser
      };
      this.userSubject.next(user);
    }
  }

  setUser(authUser: firebase.User): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (authUser) {
      const authData: UserDataFb = this.convertToUserDataFb(authUser);
      localStorage.setItem(this.localStorageKey, JSON.stringify(authData));

      this.userSubscription = this.userDbService
        .get(authUser.uid)
        .valueChanges()
        .pipe(
          catchError((err) => {
            console.error('Fehler beim Abrufen von Firestore-Daten:', err);
            return of(null);
          }),
          takeUntil(this.onDestroy)
        )
        .subscribe((dbData) => {
          dbData.id = authUser.uid;

          const user: User = {
            authData,
            dbData
          };
          this.userSubject.next(user);
        });
    } else {
      this.userSubject.next(null);
      localStorage.removeItem(this.localStorageKey);
    }
  }

  private convertToUserDataFb(authUser: firebase.User): UserDataFb {
    return {
      uid: authUser.uid,
      displayName: authUser.displayName,
      email: authUser.email,
      photoURL: authUser.photoURL,
      emailVerified: authUser.emailVerified
    };
  }

  getUser(): Observable<User | null> {
    return this.userSubject.asObservable();
  }

  getUserByUserCode(userCode: string): Promise<string | null> {
    return this.userDbService.getUserByUserCode(userCode);
  }

  assignToAllUsers(): void {
    this.userDbService.assignToAllUsers();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
