// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const environmentPegasus = {
  name: 'Pegasus',
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBlY_48QVC3ZRWO5vPhYYRkinSJPHI0yDI',
    authDomain: 'pegasus-focushorse.firebaseapp.com',
    projectId: 'pegasus-focushorse',
    storageBucket: 'pegasus-focushorse.appspot.com',
    databaseUrl: 'https://pegasus-focushorse-default-rtdb.europe-west1.firebasedatabase.app',
    messagingSenderId: '555594211377',
    appId: '1:555594211377:web:8810f7b51e43d33ae0f510',
    measurementId: 'G-PQMMCFLN9M'
  }
};

export const environment = environmentPegasus;

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
