<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon slot="start" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ !isEdit ? 'Profil' : 'Profil bearbeiten' }}</ion-title>
    <ion-buttons *ngIf="isEdit" slot="end">
      <ion-button (click)="submitUser(form)" [strong]="true" [disabled]="!form.dirty && !selectedFile"
        >Aktualisieren</ion-button
      >
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container" style="margin-bottom: 30px" *ngIf="!showQrCode">
    <div
      class="user-image-container"
      [ngClass]="{ fullscreen: isFullScreen }"
      [ngStyle]="{ cursor: isEdit ? 'pointer' : 'default' }"
    >
      <ion-avatar
        *ngIf="selectedImageUrl"
        class="custom-avatar"
        (click)="isEdit ? openFileInputDialog() : toggleFullScreen()"
      >
        <img [src]="selectedImageUrl" />
      </ion-avatar>
      <div *ngIf="!selectedImageUrl" class="custom-avatar default" (click)="isEdit && openFileInputDialog()">
        <ion-icon name="person-outline"></ion-icon>
      </div>

      <input
        type="file"
        id="fileupload"
        multiple
        (change)="selectFile($event)"
        style="display: none"
        accept="image/png, image/jpeg, image/jpg, image/gif, image/*"
      />
    </div>
    <ion-icon class="edit-icon" name="create-outline" style="cursor: pointer" (click)="isEdit = !isEdit"></ion-icon>
  </div>

  <ion-grid fixed>
    <form #form="ngForm" *ngIf="!showQrCode">
      <ion-card class="ion-card-tint" style="margin-bottom: 0px !important">
        <ion-list class="ion-card-tint less-margin no-last-border">
          <ion-item class="ion-item-tint">
            <!-- Edit Mode -->
            <ng-template [ngIf]="isEdit" [ngIfElse]="viewModeVorname">
              <ion-label class="title">Vorname *</ion-label>
              <ion-input
                type="text"
                placeholder="Vorname"
                name="vorname"
                #vorname="ngModel"
                [(ngModel)]="user.vorname"
                required
                minlength="1"
                [class.is-invalid]="vorname.invalid && (vorname.dirty || vorname.touched)"
              ></ion-input>
              <div *ngIf="vorname.invalid && (vorname.dirty || vorname.touched)" class="invalid-feedback">
                <div *ngIf="vorname.errors?.['required']">Der Vorname wird benötigt</div>
              </div>
              <ion-icon *ngIf="isEdit" name="create-outline"></ion-icon>
            </ng-template>

            <!-- View Mode -->
            <ng-template #viewModeVorname>
              <ion-label class="title">Vorname</ion-label>
              <ion-label class="value">{{ user?.vorname }}</ion-label>
            </ng-template>
          </ion-item>

          <ion-item class="ion-item-tint">
            <!-- Edit Mode -->
            <ng-template [ngIf]="isEdit" [ngIfElse]="viewModeNachname">
              <ion-label class="title">Nachname *</ion-label>
              <ion-input
                type="text"
                placeholder="Nachname"
                name="nachname"
                #nachname="ngModel"
                [(ngModel)]="user.nachname"
                required
                minlength="1"
                [class.is-invalid]="nachname.invalid && (nachname.dirty || nachname.touched)"
              ></ion-input>
              <div *ngIf="nachname.invalid && (nachname.dirty || nachname.touched)" class="invalid-feedback">
                <div *ngIf="nachname.errors?.['required']">Der Nachname wird benötigt</div>
              </div>
              <ion-icon *ngIf="isEdit" name="create-outline"></ion-icon>
            </ng-template>

            <!-- View Mode -->
            <ng-template #viewModeNachname>
              <ion-label class="title">Nachname</ion-label>
              <ion-label class="value">{{ user?.nachname }}</ion-label>
            </ng-template>
          </ion-item>

          <ion-item class="ion-item-tint">
            <!-- Edit Mode -->
            <ng-template [ngIf]="isEdit" [ngIfElse]="viewMode">
              <ion-label class="title">E-Mail *</ion-label>
              <ion-input
                type="email"
                placeholder="Name"
                name="email"
                #email="ngModel"
                [(ngModel)]="user.email"
                required
                minlength="1"
                [class.is-invalid]="email.invalid && (email.dirty || email.touched)"
              ></ion-input>
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                <div *ngIf="email.errors?.['required']">Die E-Mail-Adresse wird benötigt</div>
                <div *ngIf="email.errors?.['email']">Keine gültige E-Mail-Adresse</div>
              </div>
              <ion-icon *ngIf="isEdit" name="create-outline"></ion-icon>
            </ng-template>

            <!-- View Mode -->
            <ng-template #viewMode>
              <ion-label class="title">E-Mail</ion-label>
              <ion-label class="value">{{ user?.email }}</ion-label>
            </ng-template>
          </ion-item>
        </ion-list>
      </ion-card>

      <ion-card *ngIf="!user.vorname || !user.nachname" class="ion-padding">
        <div style="display: flex; align-items: center">
          <ion-icon name="warning-outline" color="warning" style="font-size: 5em"></ion-icon>
          <div style="flex-grow: 1; margin-left: 10px">
            <ion-text color="warning">
              Um über dieses Profil Analysen hochladen oder das Pferd für andere freigeben zu können, sind die obigen
              Informationen notwendig.
            </ion-text>
          </div>
        </div>
      </ion-card>

      <ion-card class="ion-card-tint" style="margin-top: 30px !important; margin-bottom: 20px !important">
        <ion-list class="ion-card-tint less-margin no-last-border">
          <ion-item class="ion-item-tint">
            <!-- Edit Mode -->
            <ng-template [ngIf]="isEdit" [ngIfElse]="viewModeStrasse">
              <ion-label class="title">Straße</ion-label>
              <ion-input
                type="text"
                placeholder="Straße"
                name="strasse"
                #strasse="ngModel"
                [(ngModel)]="user.strasse"
              ></ion-input>
              <ion-icon *ngIf="isEdit" name="create-outline"></ion-icon>
            </ng-template>

            <!-- View Mode -->
            <ng-template #viewModeStrasse>
              <ion-label class="title">Straße</ion-label>
              <ion-label class="value">{{ user?.strasse }}</ion-label>
            </ng-template>
          </ion-item>

          <ion-item class="ion-item-tint">
            <!-- Edit Mode -->
            <ng-template [ngIf]="isEdit" [ngIfElse]="viewModePLZ">
              <ion-label class="title">PLZ</ion-label>
              <ion-input type="text" placeholder="PLZ" name="plz" #plz="ngModel" [(ngModel)]="user.plz"></ion-input>
              <ion-icon *ngIf="isEdit" name="create-outline"></ion-icon>
            </ng-template>

            <!-- View Mode -->
            <ng-template #viewModePLZ>
              <ion-label class="title">PLZ</ion-label>
              <ion-label class="value">{{ user?.plz }}</ion-label>
            </ng-template>
          </ion-item>

          <ion-item class="ion-item-tint">
            <!-- Edit Mode -->
            <ng-template [ngIf]="isEdit" [ngIfElse]="viewModeOrt">
              <ion-label class="title">Ort</ion-label>
              <ion-input type="text" placeholder="Ort" name="ort" #ort="ngModel" [(ngModel)]="user.ort"></ion-input>
              <ion-icon *ngIf="isEdit" name="create-outline"></ion-icon>
            </ng-template>

            <!-- View Mode -->
            <ng-template #viewModeOrt>
              <ion-label class="title">Ort</ion-label>
              <ion-label class="value">{{ user?.ort }}</ion-label>
            </ng-template>
          </ion-item>
        </ion-list>
      </ion-card>
    </form>

    <ng-container *ngIf="showQrCode">
      <qrcode [qrdata]="qrAdress" [width]="300" [errorCorrectionLevel]="'M'" [cssClass]="'center'"
        [imageSrc]="'assets/icon/horseQr.svg'" [imageHeight]="100" [imageWidth]="100">
      </qrcode>

      <ion-item lines="none">
        <ion-input aria-label="Empfehlungscode" value="{{qrAdressShort}}" readonly
          style="border: 1px solid #000; padding: 0 5px !important"></ion-input>
        <div style="background-color: var(--ion-color-primary); padding: 11px" (click)="copyQrToClipboard()">
          <ion-icon name="copy" color="light" style="cursor: pointer"></ion-icon>
        </div>
      </ion-item>

      <ion-item lines="none" style="margin-top: 20px">
        <ion-label class="ion-text-wrap">
          Durch das Scannen dieses Empfehlungscodes bei der Registrierung eines <b>focus horse</b> Accounts...
        </ion-label>
      </ion-item>

      <ion-card style="margin-top: 20px">
        <ion-list lines="none">
          <ion-item class="custom-ion-item-icon" button mode="ios" (click)="showQrCode = false">
            <ion-icon slot="start" name="chevron-back-outline" class="custom-ion-item-icon-color"></ion-icon>
            <ion-label>Zurück zum Benutzerprofil</ion-label>
          </ion-item>
        </ion-list>
      </ion-card>
    </ng-container>

    <ion-card style="margin: 20px 20px" *ngIf="!isEdit && !showQrCode">
      <ion-list lines="none">
        <ion-item button mode="ios" (click)="showQrCode = !showQrCode">
          <ion-icon [src]="'/assets/icon/qrcode.svg'" class="icon-space"></ion-icon>
          <ion-label style="margin-left: 10px;">Empfehlungscode</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>

    <ion-button
      *ngIf="isEdit"
      expand="block"
      color="secondary"
      style="margin: 15px 20px"
      (click)="openModalChangePassword()"
    >
      <ion-icon slot="start" name="key-outline"></ion-icon>
      <ion-label>Passwort ändern</ion-label>
    </ion-button>

    <ion-button
      *ngIf="isEdit"
      expand="block"
      style="margin: 15px 20px"
      (click)="submitUser(form)"
      [disabled]="!form.dirty && !selectedFile"
    >
      Profil aktualisieren
    </ion-button>
  </ion-grid>
</ion-content>
