// miscellaneous.ts

/**
 * Eine asynchrone Wartefunktion, die die Ausführung für eine angegebene Zeit verzögert.
 * @param time - Die Zeitdauer in Millisekunden, für die die Ausführung pausiert werden soll.
 */
export const wait = async (time: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

/* 
   Beispiel für die Verwendung:
   import { wait } from './miscellaneous';

   class IhreKomponenteOderService {
     async performActionWithDelay() {
       console.log('Aktion startet...');
       await wait(2000); // Wartet 2 Sekunden
       console.log('2 Sekunden vergangen, Aktion fortgesetzt.');
     }
   }
*/
