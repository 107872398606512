<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" *ngIf="authService.isLoggedIn">
      <ion-content>
        <div style="cursor: pointer" (click)="openModalPersonalData()">
          <ion-avatar *ngIf="profileImageUrl" class="custom-avatar">
            <img [src]="profileImageUrl" />
          </ion-avatar>
          <!-- <div *ngIf="!profileImageUrl" class="custom-avatar default">
            <ion-icon name="person-outline"></ion-icon>
          </div> -->

          <div *ngIf="user?.vorname || user?.nachname" class="user-name-area">
            <ion-label> {{ user.vorname }} {{ user.nachname }} </ion-label>
            <ion-label>
              {{ user.email }}
            </ion-label>
          </div>
        </div>

        <ion-list lines="none">
          <ion-menu-toggle autoHide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header> Profil </ion-list-header>

          <!-- <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/account" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person"></ion-icon>
              <ion-label>
                Account
              </ion-label>
            </ion-item>
          </ion-menu-toggle> -->

          <!-- <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Support
              </ion-label>
            </ion-item>
          </ion-menu-toggle> -->

          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="signOut()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label> Abmelden </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <!--
        <ion-list lines="none">
          <ion-list-header>
            Hilfe
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="openTutorial()" detail="false">
              <ion-icon slot="start" name="hammer"></ion-icon>
              <ion-label>Anleitung</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list> -->

        <ion-list *ngIf="!loggedIn" lines="none">
          <ion-list-header> Einstellungen </ion-list-header>

          <ion-item>
            <ion-icon slot="start" name="moon-outline"></ion-icon>
            <ion-label> Dark Mode </ion-label>
            <ion-toggle [(ngModel)]="dark"></ion-toggle>
          </ion-item>


          <div style="margin: 20px 40px; float: right; text-align: right">
            <ion-label style="display: block; color: gray; font-size: 0.7em;">Version: {{ version }}</ion-label>
            <ion-label style="display: block; font-size: 0.7em; color: darkgray" (click)="onVersionClick($event)">({{
              buildInfo.date }} {{ buildInfo.time
              }})</ion-label>
            <ion-label *ngIf="environmentName !== 'Focus'" style="display: block; font-size: 0.6em; color: darkgray">({{
              environmentName }})</ion-label>
          </div>

          <!-- Link zu Impressum / Datenschutz -->
          <ion-item lines="none">
            <ion-label style="text-align: center;">
              <a style="color: gray;" href="https://focus-horse.de/index.php/impressum-datenschutz/">Impressum / Datenschutz</a>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
