// utils/index.ts

/**
 * Zentrale Utility-Import-Datei
 * 
 * Inhaltsverzeichnis:
 * 
 * 1. ID-Generierung:
 *    - Funktionen zur Erzeugung einzigartiger Identifikatoren und Passwörter.
 *    - Beinhaltet: uuid, uuidChars, uuidPass
 * 
 * 2. Validierung:
 *    - Funktionen zur Überprüfung von Objekten und Werten.
 *    - Beinhaltet: isObjEmpty, isInteger, isFloat
 * 
 * 3. Objekt- und Array-Manipulation:
 *    - Funktionen zur Bearbeitung von Objekten und Arrays, insbesondere für Sortierung und Aktualisierung.
 *    - Beinhaltet: updateNestedProp, sortNestedProp, sortUtil
 * 
 * 4. Datums- und Zeitformatierung:
 *    - Funktionen zum Formatieren von Datums- und Zeitangaben.
 *    - Beinhaltet: formatDate, formatDateTime, formatTime
 * 
 * 5. Zahlenformatierung:
 *    - Funktionen zur Formatierung von Zahlen und Prozentwerten.
 *    - Beinhaltet: formatPercentage, formatDecimal
 * 
 * 6. String-Manipulation:
 *    - Funktionen zur Bearbeitung und Formatierung von Strings.
 *    - Beinhaltet: kFormatter, htmlToString, capitalize
 * 
 * 7. Verschiedenes:
 *    - Andere nützliche Funktionen.
 *    - Beinhaltet: wait
 */

export * from './idGenerators';
export * from './validation';
export * from './objectArrayManipulation';
export * from './dateFormatting';
export * from './numberFormatting';
export * from './stringManipulation';
export * from './miscellaneous';
