import { HorseDbService } from './../../services/horses.db.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { take, tap } from 'rxjs/operators';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import firebase from 'firebase';
import { FileService } from '../../services/file.service';
import { LoadingService } from '../../services/loading.service';
import FileData from '../../models/file-data.model';
import { UserService } from '../../services/user.service';
import { UserDbService } from '../../services/users.db.service';
import { UserDataDb } from '../../models/user.model';
import { ChangePasswordFormModalComponent } from '../change-password-form-modal/change-password-form-modal.component';
import { AuthenticationService } from '../../services/authentication.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-user-form-modal',
  templateUrl: './user-form-modal.component.html',
  styleUrls: ['./user-form-modal.component.scss']
})
export class UserFormModalComponent implements OnInit {
  user: UserDataDb;
  authUser: firebase.User;
  isEdit: boolean = false;
  originalEmail: string;
  originalName: string;
  
  showQrCode: boolean = false;
  baseAddress: string;
  qrAdress: string;
  qrAdressShort: string;

  constructor(
    private userService: UserService,
    private userDbService: UserDbService,
    private horseDbService: HorseDbService,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private modalController: ModalController,
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    private dialogService: DialogService,
    private location: Location,
    private toastController: ToastController,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.baseAddress = this.document.location.origin;

    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.user = user.dbData;
        this.selectedImageUrl = this.user?.profileImageUrl;
        this.originalEmail = this.user?.email;
        this.originalName = `${this.user?.vorname} ${this.user?.nachname}`;

        this.qrAdressShort = `${user?.dbData.userCode}`;
        this.qrAdress = `${this.baseAddress}/referral/${user?.dbData.userCode}`;
      });
  }

  async submitUser(form: NgForm): Promise<void> {
    if (!this.validateForm(form)) {
      return;
    }

    try {
      this.loadingService.showLoading();
      const updateSuccess = await this.updateUser();

      if (updateSuccess) {
        // Only show the success alert if the update was successful.
        this.dialogService.presentAlert(
          `Ihr Profil wurde erfolgreich ${this.isEdit ? 'aktualisiert' : 'gespeichert'}.`
        );
      }
    } catch (error) {
      console.log(`Fehler beim Speichern des Profils: ${error}`);
      this.dialogService.presentAlert(`Fehler beim Speichern des Profils:<br><br>${error.message}`);
    } finally {
      this.loadingService.hideLoading();
    }
  }

  closeModal(data?: any) {
    this.modalController.dismiss(data);
  }

  async updateUser(): Promise<boolean> {
    // Check if the email has been changed.
    if (this.originalEmail !== this.user.email) {
      // Prompt the user for their current password
      this.loadingService.hideLoading();
      const currentPassword = await this.promptForPassword();

      if (currentPassword) {
        // If the user provides their password, update the email in Firebase Authentication.
        this.loadingService.showLoading();
        await this.authService.ChangeEmail(currentPassword, this.user.email);
      } else {
        // Handle the scenario where the user cancels the password prompt or doesn't provide their password.
        this.dialogService.presentAlert(
          'Eine E-Mail-Änderung kann ohne eine erneute Passworteingabe nicht durchgeführt werden.'
        );
        return false; // Exit the function to prevent further updates.
      }
    }

    // Extract properties from the user object.
    const { id, ...userWithoutId } = this.user;

    // Handle profile image change
    if (this.user.profileImageUrl != this.selectedImageUrl && this.photoData) {
      // Now we have access to userId
      this.photoData.userId = id;

      // Then perform the upload
      await this.uploadProfilPhoto();

      // Set the new profileImageUrl
      userWithoutId.profileImageUrl = this.photoData.url;
    }

    // Finally, update the user in the database.
    await this.userDbService.update(this.user.id, userWithoutId);

    const actualName = `${this.user?.vorname} ${this.user?.nachname}`;
    if (actualName !== this.originalName) {
      this.horseDbService.updateAllHorsesFromUserWithOwnerName(id, actualName);
    }

    this.originalEmail = this.user.email;
    this.originalName = `${this.user?.vorname} ${this.user?.nachname}`;

    return true;
  }

  async promptForPassword(): Promise<string> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Passwortbestätigung',
        message: 'Bitte geben Sie Ihr aktuelles Passwort ein, um Ihre E-Mail-Adresse ändern zu können.',
        inputs: [
          {
            name: 'password',
            type: 'password',
            placeholder: 'Aktuelles Passwort eingeben'
          }
        ],
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            handler: () => {
              resolve(null);
            }
          },
          {
            text: 'Bestätigen',
            handler: (data) => {
              resolve(data.password);
            }
          }
        ]
      });

      await alert.present();
    });
  }

  async uploadProfilPhoto(): Promise<void> {
    this.uploadOngoing = true;

    try {
      this.uploadPercentage = 0;

      await this.fileService
        .uploadUserProfilePhoto(this.photoData)
        .pipe(
          tap((percentage) => {
            this.uploadPercentage = Math.round(percentage || 0);
          })
        )
        .toPromise();

      this.photoData.fileUploaded = true;
      this.uploadFinished = true;
    } catch (error) {
      const errorMessage = `Fehler beim Upload vom Profilfoto: ${error}`;
      console.log(errorMessage);
      this.dialogService.presentAlert(errorMessage);
      throw new Error(errorMessage);
    }
  }

  validateForm(form: NgForm): boolean {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return false;
    }
    return true;
  }

  selectedImageUrl: SafeUrl | undefined;
  selectedFile?: File;
  photoData?: FileData;
  uploadPercentage: number;
  uploadOngoing = false;
  uploadFinished = false;

  openFileInputDialog() {
    // let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    let element: HTMLElement = document.getElementById('fileupload') as HTMLElement;
    element.click();
  }

  async selectFile(event: any) {
    if (!event.target.files.length) {
      this.dialogService.presentAlert('Es ist kein Foto zum hochladen ausgewählt.');
      return;
    }

    this.selectedFile = event.target.files[0];

    if (this.selectedFile.size > 1000000) {
      // 1MB = 1,000,000 Bytes
      this.dialogService.presentAlert('Die Datei ist zu groß. Bitte wählen Sie eine Datei unter 1MB aus.');
      return;
    }

    this.photoData = {} as FileData;
    this.photoData.uploadDate = new Date();
    this.photoData.uploadDateIso = this.photoData.uploadDate.toISOString();

    const file: File | null = this.selectedFile;
    this.photoData.file = file;
    this.photoData.metadata = {
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      lastModifiedDate: new Date(file.lastModified)
    };

    const objectUrl = URL.createObjectURL(file);
    // Bypass Angular's security check for this specific URL
    this.selectedImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
  }

  isFullScreen: boolean = false;

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  async openModalChangePassword() {
    const modal = await this.modalCtrl.create({
      component: ChangePasswordFormModalComponent
    });

    modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      // use the data here
    }
  }

  public copyQrToClipboard(position: 'top' | 'middle' | 'bottom' = 'middle') {
    var name = `${this.user?.vorname} ${this.user?.nachname}`;

    navigator.clipboard
      .writeText(this.qrAdressShort)
      .then(() => {
        const message = `Der Empfehlungscode für ${name} wurde in die Zwischenablage kopiert...`;
        this.presentToast(message, position);
      })
      .catch((error) => {
        const message = `Fehler beim Kopieren des Empfehlungscodes für ${name} in die Zwischenablage.`;
        this.presentToast(message, position);
      });
  }

  async presentToast(message: string, position: 'top' | 'middle' | 'bottom' = 'middle', duration = 2000) {
    const toast = await this.toastController.create({
      message,
      duration,
      position
    });

    await toast.present();
  }
}
