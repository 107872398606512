import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading = false;
  private loadingOverlay: HTMLIonLoadingElement;
  private isLoggingEnabled = false;  // Logging aktiviert oder deaktiviert

  constructor(private loadingController: LoadingController) {}

  async showLoading() {
    this.log('showLoading aufgerufen');
    if (!this.isLoading) {
      this.isLoading = true;
      this.loadingOverlay = await this.loadingController.create({
        spinner: 'dots',
        translucent: true,
        duration: 1000  // Overlay wird nach 5 Sekunden automatisch geschlossen
      });
      await this.loadingOverlay.present();
      this.log('loadingOverlay präsentiert');
      this.loadingOverlay?.onDidDismiss().then(() => {
        this.log('loadingOverlay wurde geschlossen');
        this.loadingOverlay = null;
        this.isLoading = false;
      });
    } else {
      this.log('loadingOverlay ist bereits aktiv');
    }
  }
  
  async hideLoading() {
    this.log('hideLoading aufgerufen');
    if (this.isLoading && this.loadingOverlay) {
      await this.loadingOverlay.dismiss();
      this.log('loadingOverlay dismiss Methode aufgerufen');
    } else {
      this.log('loadingOverlay ist nicht aktiv, daher nicht geschlossen');
    }
  }  

  private log(message: string) {
    if (this.isLoggingEnabled) {
      console.log(message);
    }
  }

  // Methode um Logging zu aktivieren oder deaktivieren
  setLogging(isEnabled: boolean) {
    this.isLoggingEnabled = isEnabled;
  }
}