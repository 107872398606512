export default class FileData {
  url: string;
  filename?: string;
  fileExtension?: string;
  file?: File;
  fileFromCache: boolean;
  fileUploaded?: boolean;

  uploadDate: Date;
  uploadDateIso: string;

  metadata?: {
    size: number;
    type: string;
    lastModified: number;
    lastModifiedDate: Date;
  };

  horseId?: string;
  horseAlias?: string;

  userId?: string;

  inhalt?: {
    gang: Gang;
    richtung: Richtung;
    reiter: Reiter;
  };
}

export enum Gang {
  Schritt = 's',
  Trab = 't',
  Galopp = 'g'
}

export enum Richtung {
  LinksNachRechts = 'l',
  RechtsNachLinks = 'r'
}

export enum Reiter {
  MitReiter = 'm',
  OhneReiter = 'o'
}
